/* eslint-disable no-console */
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, LinearProgress, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment-timezone";
import React, { useState } from "react";
import { downloadOrder } from "../../api";

import { useAuth } from "../../contexts/AuthContext";
import useTable from "../../hooks/useTable";
import { downloadPdf, formatAmount, formatPlate } from "../../utils";
import TableFooter from "../FooterTable";
import TagStatus from "../TagStatus";

export interface PropsOrderTable {
  order_id: number;
  plate: string;
  source: string;
  created: Date;
  reason: string;
  comment: string;
  user_name: string;
  count_status_ticket: {
    "1": number;
    "2": number;
    "3": number;
    "5": number;
    "6": number;
    "7": number;
    "9": number;
    "23": number;
  };
  status?: number;
  tickets: PropsResponseGetOrders[];
}
export interface PropsResponseGetOrders {
  comment: string;
  compartment: number;
  created: string;
  order_id: number;
  product: string;
  quantity: number;
  reason: string;
  source: string;
  status: 1 | 2 | 3 | 5 | 6 | 7 | 9 | 23;
  ticket_id: number;
  truck_plate: string;
  user_document: string;
  user_name: string;
}

const style = {
  position: "absolute" as "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1020,
  bgcolor: "background.paper",
  // pt: 4,
  // px: 6,
  pb: 6,
  borderRadius: "1.5rem",
};

function Row(props: { row: PropsOrderTable }) {
  const authContext = useAuth();
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            borderRadius: "1.6rem 0rem 0rem 1.6rem",
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        />
        <TableCell
          sx={{
            borderRadius: "0rem 0rem 0rem 0rem",
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          {row.order_id}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          {formatPlate(row.plate)}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          {row.source}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          {row.tickets.length === row.count_status_ticket["1"] ? (
            <TagStatus status={1} />
          ) : row.tickets.length === row.count_status_ticket["2"] ? (
            <TagStatus status={2} />
          ) : (row.count_status_ticket["23"] > 0 &&
              row.tickets.length ===
                row.count_status_ticket["1"] +
                  row.count_status_ticket["2"] +
                  row.count_status_ticket["23"]) ||
            row.count_status_ticket["1"] + row.count_status_ticket["2"] ===
              row.tickets.length ? (
            <TagStatus status={23} />
          ) : (
            <TagStatus status={9} />
          )}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
          align="left"
        >
          <Button
            variant="text"
            style={{ textTransform: "none", fontSize: "1.4rem" }}
            onClick={handleOpenModal}
          >
            Ver mais
          </Button>
          <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={{ ...style, width: 780 }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  // paddingBottom: "5.6rem",
                  padding: "0 2.4rem",
                }}
              >
                <h3>Detalhes da ordem {row.order_id}</h3>
                <button
                  onClick={handleCloseModal}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <img src="/close-icon.svg" alt="close icon" />
                </button>
              </div>

              <div
                style={{
                  height: "0.5px",
                  backgroundColor: "#B0C1E1",
                  opacity: 0.7,
                }}
              >
                {" "}
              </div>

              {/* {row.tickets.map(() => )} */}

              <div
                style={{
                  backgroundColor: "#F8F8FB",
                  height: "60px",
                  display: "flex",
                  flexDirection: "row",
                  color: "#626166",
                  fontSize: "1.4rem",
                  padding: "0 2.4rem",
                  marginTop: "4rem",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Criador da ordem
                  </h3>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Motivo
                  </h3>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#626166",
                  fontSize: "1.4rem",
                  padding: "0 2.4rem",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    {row.user_name ? row.user_name : "---"}
                  </h3>
                </div>

                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    {row.reason
                      ? row.reason
                      : "Não foi possível encontrar o motivo da ordem"}
                  </h3>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#F8F8FB",
                  height: "60px",
                  color: "#626166",
                  fontSize: "1.4rem",
                  padding: "0 2.4rem",
                  marginTop: "4rem",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    Comentário
                  </h3>
                </div>
              </div>

              <div
                style={{
                  color: "#626166",
                  fontSize: "1.4rem",
                  padding: "0 2.4rem",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "left",
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: 400,
                    }}
                  >
                    {row.comment ? row.comment : "Sem comentários."}
                  </h3>
                </div>
              </div>
            </Box>
          </Modal>
        </TableCell>
        <TableCell
          sx={{
            color: "#D91F05",
            fontWeight: 500,
            borderRadius: "0rem",
            border: "none",
          }}
          align="center"
        >
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={
              row.tickets.length === row.count_status_ticket["1"] ? false : true
            }
            onClick={async () => {
              try {
                const response = await downloadOrder(
                  `${row.order_id}`,
                  authContext ? authContext.customCenterID : ""
                );

                downloadPdf(response);
              } catch (err) {
                console.log("error");
              } finally {
              }
            }}
            color="primary"
          >
            <DownloadIcon />
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            color: "#D91F05",
            fontWeight: 500,
            borderRadius: "0rem 1.6rem 1.6rem 0rem",
            border: "none",
          }}
        >
          Ver tickets
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            color="primary"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <Box sx={{ padding: !open ? 0 : "0.4rem", backgroundColor: "#F4F7F9" }}>
        {" "}
      </Box>
      <TableRow>
        <TableCell
          sx={{ borderRadius: "1.6rem" }}
          style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto">
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        color: "#626166",
                        fontWeight: 500,
                      }}
                    >
                      Ticket
                    </TableCell>
                    <TableCell
                      sx={{ border: "none", color: "#626166", fontWeight: 500 }}
                    >
                      Produto
                    </TableCell>
                    <TableCell
                      sx={{ border: "none", color: "#626166", fontWeight: 500 }}
                      align="right"
                    >
                      Quantidade(LT)
                    </TableCell>
                    <TableCell
                      sx={{ border: "none", color: "#626166", fontWeight: 500 }}
                      align="center"
                    >
                      Compartimento
                    </TableCell>
                    <TableCell
                      sx={{ border: "none", color: "#626166", fontWeight: 500 }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{ border: "none", color: "#626166", fontWeight: 500 }}
                    >
                      Data de criação
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.tickets.map((detailsRow) => (
                    <TableRow key={detailsRow.ticket_id}>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                      >
                        {detailsRow.ticket_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                      >
                        {detailsRow.product}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                        align="right"
                      >
                        {formatAmount(detailsRow.quantity)}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                        align="center"
                      >
                        {detailsRow.compartment}
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                      >
                        <TagStatus status={detailsRow.status} />
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                          color: "#626166",
                          fontWeight: 400,
                          paddingBottom: "2.4rem",
                        }}
                      >
                        {moment(detailsRow.created).format("DD/MM/yyyy")}
                        <br />
                        às {moment(detailsRow.created).format("HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <div style={{ padding: "0.8rem", backgroundColor: "#F4F7F9" }}> </div>
    </>
  );
}

export interface Props {
  data: Object[];
  rowsPerPage: number;
}

type CustomRenderers<T> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

interface TableProps<T> {
  items: Object[];
  customRenderers?: CustomRenderers<T>;
  rowsPerPage: number;
  loading?: boolean;
}

export default function TableOrders<T>(props: TableProps<T>) {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(props.items, page, props.rowsPerPage);
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "0.8rem",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        {props.loading && <LinearProgress variant="indeterminate" />}
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#EEF0F5",
              color: "#626166",
              marginBottom: "1.2rem",
              borderRadius: "0.8rem",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "#626166",
                  border: "none",
                  borderRadius: "0.8rem 0rem 0rem 0.8rem",
                }}
              />
              <TableCell
                sx={{
                  color: "#626166",
                  border: "none",
                  borderRadius: "0.8rem 0rem 0rem 0.8rem",
                }}
              >
                Ordem de carregamento
              </TableCell>
              <TableCell sx={{ color: "#626166", border: "none" }}>
                Placa
              </TableCell>
              <TableCell sx={{ color: "#626166", border: "none" }}>
                Origem
              </TableCell>

              <TableCell sx={{ color: "#626166", border: "none" }}>
                Status
              </TableCell>
              <TableCell sx={{ color: "#626166", border: "none" }} align="left">
                Detalhes da ordem
              </TableCell>
              <TableCell
                sx={{ color: "#626166", border: "none" }}
                align="center"
              >
                Baixar ordem
              </TableCell>
              <TableCell
                sx={{
                  color: "#626166",
                  border: "none",
                  borderRadius: "0rem 0.8rem 0.8rem 0rem",
                }}
              >
                Detalhes de tickets
              </TableCell>
            </TableRow>
          </TableHead>
          <div style={{ backgroundColor: "#F4F7F9", padding: "0.8rem" }}> </div>
          <TableBody sx={{ backgroundColor: "white" }}>
            {slice.map((row) => (
              <>
                <Row key={row.order_id} row={row} />
              </>
            ))}
          </TableBody>
        </Table>
        <TableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
      </TableContainer>
    </>
  );
}
