import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { reasons } from "..";
import { StyledButton } from "../../../components/StyledButton";
import { maskVehiclePlateMercoSul, onlyNumber } from "../../../utils";
import {
  IFormCreateOrderService,
  IProduct,
  products,
} from "../../../utils/interfaces";
import { useForm } from "../FormContext";
import { TableProducts } from "./TableProduct";
import { v4 as uuidv4 } from "uuid";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#626166",
    padding: "1.6rem",
    border: "none",
    borderRadius: "0.4rem",
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}

const FormItems: React.FC = () => {
  const [, setProductSelectedToExclude] = useState<IProductSelecteToExclude>(
    {}
  );
  const { values, touched, errors, setFieldValue, resetForm, setFieldTouched } =
    useFormikContext<IFormCreateOrderService>();
  const formContext = useForm();

  const removeProducts = () => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  const handleAddProduct = (productSelected: string) => {
    const product = products.find(
      (ele) => ele.product === productSelected
    ) as IProduct;

    try {
      product.idx = uuidv4();
      setFieldValue("products", [
        ...values.products,
        { ...product, quantity: 1000 },
      ]);
    } catch (error) {}
  };
  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  return (
    <div>
      <Box
        sx={{
          paddingTop: "3.2rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ width: "70%", paddingRight: "12rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "35%",
              }}
            >
              <label
                style={{
                  color: "#626166",
                  fontWeight: 600,
                  paddingBottom: "1.2rem",
                }}
              >
                Número de ordem de carregamento
              </label>
              {values.reason ===
                "5 - Abastecimento Consumo Interno - Geradores" ||
              values.reason ===
                "6 - Abastecimento Consumo Interno - Motor de combate a incêndio" ||
              values.reason === "7 - Abastecimento - Outros" ||
              values.reason === "10 - Complemento de descarga" ? (
                <>
                  <Field
                    placeholder="Insira o código da ordem de carregamento"
                    name="id_erp"
                    id="id_erp"
                    onChange={(e: { target: { value: string } }) => {
                      setFieldValue("id_erp", "");
                    }}
                    value=""
                    disabled
                    style={{
                      backgroundColor: "#F0F0F0",
                      color: "#626166",
                      padding: "1.6rem",
                      border: "none",
                      borderRadius: "0.4rem",
                      outline: "none",
                    }}
                  />
                  {touched.id_erp && errors.id_erp && (
                    <div style={{ color: "#D91F05", fontSize: "0.8rem" }}>
                      {errors.id_erp}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Field
                    placeholder="Insira o código da ordem de carregamento"
                    // value={values.id_erp}
                    name="id_erp"
                    id="id_erp"
                    onChange={(e: { target: { value: string } }) =>
                      setFieldValue("id_erp", onlyNumber(e.target.value))
                    }
                    style={{
                      backgroundColor: "white",
                      color: "#626166",
                      padding: "1.6rem",
                      border: "none",
                      borderRadius: "0.4rem",
                      outline: "none",
                    }}
                  />
                  {touched.id_erp && errors.id_erp && (
                    <div style={{ color: "#D91F05", fontSize: "0.8rem" }}>
                      {errors.id_erp}
                    </div>
                  )}
                </>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "35%",
              }}
            >
              <label
                style={{
                  color: "#626166",
                  fontWeight: 600,
                  paddingBottom: "1.2rem",
                }}
              >
                Placa
              </label>
              <Field
                id="placa"
                name="placa"
                placeholder="Insira a placa do veículo"
                onChange={(e: { target: { value: string } }) =>
                  setFieldValue(
                    "placa",
                    maskVehiclePlateMercoSul(e.target.value)
                  )
                }
                style={{
                  backgroundColor: "white",
                  color: "#626166",
                  padding: "1.6rem",
                  border: "none",
                  borderRadius: "0.4rem",
                  outline: "none",
                }}
              />
              {touched.placa && errors.placa && (
                <div
                  style={{
                    color: "#D91F05",
                    fontSize: "1.2rem",
                    paddingTop: "0.8rem",
                  }}
                >
                  {errors.placa}
                </div>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "4.8rem",
            }}
          >
            <FormControl sx={{ mt: 0.5 }} variant="standard">
              <label
                style={{
                  color: "#626166",
                  fontWeight: 600,
                }}
              >
                Produtos
              </label>
              <Select
                aria-placeholder="Selecione um produto"
                name="productSelected"
                id="productSelected"
                displayEmpty={true}
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue("productSelected", e.target.value);
                  handleAddProduct(e.target.value);
                }}
                input={<BootstrapInput />}
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Escolha o produto"
                }
                placeholder="Escolha o produto"
              >
                <MenuItem disabled value="">
                  <em>Escolha um produto</em>
                </MenuItem>
                <MenuItem value={"GAA"}>GAA - Gasolina A</MenuItem>
                <MenuItem value={"GASC"}>GASC - Gasolina C</MenuItem>
                <MenuItem value={"ODM"}>ODM - Diesel A S500</MenuItem>
                <MenuItem value={"ODMB"}>ODMB - Diesel B S500</MenuItem>
                <MenuItem value={"OS10"}>OS10 - Diesel A S10</MenuItem>
                <MenuItem value={"OB10"}>OB10 - Diesel B S10</MenuItem>
                <MenuItem value={"B100"}>B100 - Biodiesel</MenuItem>
                <MenuItem value={"AEAC"}>AEAC - Etanol Anidro</MenuItem>
                <MenuItem value={"AEHC"}>AEHC - Etanol Hidratado</MenuItem>
                <MenuItem value={"ODMR"}>ODMR - Diesel Marítimo</MenuItem>
              </Select>
              {values.productSelected.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: "2.4rem",
                  }}
                >
                  <Button
                    onClick={() => handleAddProduct(values.productSelected)}
                    variant="contained"
                    color="primary"
                    onKeyPress={(e) => {
                      if (e.keyCode === 13 || e.which === 13) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                    style={{ width: "22rem", textTransform: "none" }}
                  >
                    Adicionar Produto
                  </Button>
                </Box>
              )}
            </FormControl>
          </Box>

          <Box sx={{ paddingTop: "4.8rem" }}>
            <TableProducts />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              paddingTop: "2.4rem",
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => removeProducts()}
            >
              REMOVER TUDO
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              paddingTop: "4.8rem",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              // type="submit"
              sx={{
                marginRight: "8rem",
                paddingRight: "8rem",
                paddingLeft: "8rem",
                textTransform: "none",
              }}
              disabled={!Boolean(values.products.length)}
              onClick={() => resetForm()}
            >
              Cancelar
            </Button>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!Boolean(values.products.length)}
            >
              <Typography>
                {formContext.loadingCreateOrder
                  ? "Criando pedido, aguarde..."
                  : "Gerar ordem de carregamento "}
              </Typography>
              {formContext.loadingCreateOrder && (
                <CircularProgress
                  color="secondary"
                  style={{
                    marginLeft: "1rem",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              )}
            </StyledButton>
          </Box>
        </Box>

        <Box sx={{ width: "30%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "2.4rem",
            }}
          >
            {/* <label
              style={{
                color: "#626166",
                fontWeight: 600,
                paddingBottom: "1.2rem",
              }}
            >
              Motivo
            </label> */}
            <FormControl sx={{ mt: 0.5 }} variant="standard">
              <label
                style={{
                  color: "#626166",
                  fontWeight: 600,
                }}
              >
                Motivo
              </label>
              <Select
                name="reason"
                id="reason"
                onChange={(e: { target: { value: string } }) => {
                  setFieldValue("reason", e.target.value);
                  if (
                    values.reason ===
                      "5 - Abastecimento Consumo Interno - Geradores" ||
                    values.reason ===
                      "6 - Abastecimento Consumo Interno - Motor de combate a incêndio" ||
                    values.reason === "7 - Abastecimento - Outros" ||
                    values.reason === "10 - Complemento de descarga"
                  ) {
                    setFieldValue("id_erp", "");
                  }
                }}
                displayEmpty={true}
                onBlur={() => setFieldTouched("reason", true)}
                input={<BootstrapInput />}
                placeholder="Selecione o motivo"
                renderValue={(value) =>
                  value?.length
                    ? Array.isArray(value)
                      ? value.join(", ")
                      : value
                    : "Selecione o motivo"
                }
              >
                <MenuItem disabled value="">
                  <em>Selecione um motivo</em>
                </MenuItem>
                {reasons.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {touched.reason && errors.reason && (
                <div
                  style={{
                    color: "#D91F05",
                    fontSize: "1.2rem",
                    paddingTop: "0.8rem",
                  }}
                >
                  {errors.reason}
                </div>
              )}
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "4.8rem",
            }}
          >
            <label
              style={{
                color: "#626166",
                fontWeight: 600,
                paddingBottom: "1.2rem",
              }}
            >
              Observações:
            </label>
            <textarea
              rows={8}
              cols={33}
              name="comment"
              id="comment"
              onChange={({ target }) => {
                setFieldValue("comment", target.value);
              }}
              placeholder=" Digite alguma observação"
              style={{
                border: "none",
                boxShadow: "none",
                outline: "none",
                borderRadius: "0.4rem",
                resize: "none",
                fontFamily: "Open Sans, sans-serif",
                color: "#626166",
                padding: "1.2rem",
              }}
            />
            {touched.comment && errors.comment && (
              <div
                style={{
                  color: "#D91F05",
                  fontSize: "1.2rem",
                  paddingTop: "0.8rem",
                }}
              >
                {errors.comment}
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FormItems;
