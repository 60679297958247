const currentDomain = window.location.hostname.replace(/^\w+\./, "");
const cookieHost = (
  window.location.hostname === "localhost" ? "localhost" : `.${currentDomain}`
).replace(/\.+/g, "");

const currentLocation = window.location.origin;

export const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT === "development" ||
  Boolean(window.location.origin.split(".")[0].match(/-dev$/));

// function generateDevUrl(path: string) {
//   return isDevelopment ? `${path}` : path;
// }

export const appConfig = {
  api: {
    url: "https://gateway.grupoatem.com.br",
    timeout: 30000,
  },
  auth: {
    url: `https://gateway.grupoatem.com.br`,
    timeout: 30000,
    keys: { token: "@ATEM:automacao:tokenAutomacao" },
  },
  host: cookieHost,
  urlLogout: `https://authcog.grupoatem.com.br/#/logout?url=${currentLocation}&rules=carregamento`,
  urlLogoutDev:
    "http://localhost:3000/#/logout?url=http://localhost:3001&rules=carregamento",
  gateway: {
    production: "https://gateway.grupoatem.com.br",
    homologation: "http://localhost:8080TESTE",
    development: "https://gateway-dev.grupoatem.com.br",
  },
  sounds: {
    volume: 0.5,
  },
};

export const compartment = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
