export function formatPlate(plate: string) {
  if (plate.length !== 7) {
    return plate;
  }

  return `${plate.substring(0, 3)}-${plate.substring(3)}`;
}

export const formatAmount = (raw_value: number) => {
  return Intl.NumberFormat("pt-BR").format(raw_value);
};

export function maskVehiclePlateMercoSul(plate: string): string {
  const regexPlateMercoSul = new RegExp(/[a-zA-Z]{3}[0-9][a-zA-Z]\d{2}/g);
  plate = plate.replace(/\W/g, "").substring(0, 8);
  plate = plate.toUpperCase();
  if (regexPlateMercoSul.test(plate)) {
    return plate;
  } else {
    plate = plate.replace(/([a-zA-Z]{3})(.)/, "$1-$2").substring(0, 8);
    return plate;
  }
}

export function downloadPdf(data: any) {
  var file = new Blob([data], { type: "application/pdf" });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

// Function to calculate how many pages the table will have. Returning an integer.
export const calculateRange = (data: Object[], rowsPerPage: number) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);
  let i = 1;
  for (i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

// Data slice function takes as parameter an array of Objects, the page of the table, and how many items per page.
// With this, it gives a slice to the array, so that a new array is returned, with it, we will be able to check the size of the array, to use it in the tableFooter
export const sliceData = (
  data: Object[],
  page: number,
  rowsPerPage: number
) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

export const maskCPF = (CPF: string) => {
  CPF = CPF.replace(/\D/g, "");
  let masked = CPF.length > 11 ? CPF.slice(0, 11) : CPF;
  masked = masked.replace(/^(\d{3})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/(\d{3})(\d)/, "$1-$2");
  return masked;
};

const regexPlate = /^[a-zA-Z]{3}-[0-9]{4}$/;
const regexPlateMerc = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;

export function validatePlate(plate: string) {
  if (regexPlate.test(plate)) {
    return true;
  } else if (regexPlateMerc.test(plate)) {
    return true;
  } else {
    return false;
  }
}

 export function onlyNumber(input: string) {
   input = input.replace(/\D/g, "");
   let masked = input.length > 9 ? input.slice(0, 9) : input;
   return masked;
 }
