/* eslint-disable no-console */
import { Box, Button, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { createOrder, downloadOrder } from "../../../api";
import { useAuth } from "../../../contexts/AuthContext";
import { downloadPdf, validatePlate } from "../../../utils";
import AppError from "../../../utils/appError";
import {
  ICreateOrderDTO,
  IFormCreateOrderService,
} from "../../../utils/interfaces";
import { useForm } from "../FormContext";
import FormItems from "./FormItems";

const style = {
  position: "absolute" as "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1020,
  bgcolor: "background.paper",
  pt: 4,
  px: 6,
  pb: 6,
  borderRadius: "1.5rem",
};

export const NewOrderValidationSchema = Yup.object().shape({
  operacao: Yup.string().required("Este campo é obrigatório."),
  id_erp: Yup.string(),
  placa: Yup.string()
    .required("Este campo é obrigatório.")
    .test("validCPF", "Precisamos de uma placa válida", (value) =>
      value ? validatePlate(value) : false
    ),
  reason: Yup.string().required("Este campo é obrigatório."),
  comment: Yup.string().matches(
    /^[a-zA-Z1-9 -]*$/,
    "Apenas letras ou números. Verifique se contém acentos e/ou ç."
  ),
});

const FormNewOrder = () => {
  const formContext = useForm();
  const authContext = useAuth();
  const [open, setOpen] = useState(false);
  const [idErpResponse, setIdErpResponse] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [success, setSuccess] = useState<string>("");

  const initialValues: IFormCreateOrderService = {
    operacao: "carregamento",
    id_erp: "",
    placa: "",
    products: [],
    productSelected: "",
    user_name: "",
    user_document: "",
    reason: "",
    comment: "",
    source: "MANUAL",
    filial: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NewOrderValidationSchema}
      onSubmit={async (values, actions) => {
        //@ts-ignore
        const castValues = NewOrderValidationSchema.cast(
          values
        ) as IFormCreateOrderService;

        if (!castValues) return;
        const transformedData: ICreateOrderDTO = {
          filial: authContext ? authContext.customCenterID : "",
          id_sale: castValues.id_erp,
          // operacao: castValues.operacao,
          plate: castValues.placa,
          user_name: authContext ? authContext.name : "",
          user_document: authContext ? authContext.customDocument : "",
          //@ts-ignore
          compartments: castValues.products.map((item) => ({
            ...item,
            quantity: item.quantity.toString(),
          })),
          comment:
            castValues.reason === "8 - Complemento de carregamento CIF" ||
            castValues.reason === "9 - Complemento de carregamento FOB"
              ? `${castValues.id_erp} - ${castValues.comment}`
              : castValues.comment,
          reason: castValues.reason,
          source: castValues.source,
        };

        try {
          formContext.setLoadingCreateOrder(true);

          const response = await createOrder(transformedData);
          setSuccess("success");
          setIdErpResponse(response.id_sale);

          handleOpen();
        } catch (error) {
          if (error instanceof AppError) {
            if (error.message === "Ordem de Retirada já carregada") {
              setSuccess("already registered");
              handleOpen();
            } else if (error.message === "Compartimento com id incorreto") {
              setSuccess("without compartiment");
              handleOpen();
            } else {
              setSuccess("error");
              handleOpen();
            }
          }
        } finally {
          formContext.setLoadingCreateOrder(false);
        }
      }}
    >
      <Form accept-charset="utf-8">
        <FormItems />
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, width: 540 }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                paddingBottom: "5.6rem",
              }}
            >
              <button
                onClick={handleClose}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <img src="/close-icon.svg" alt="close icon" />
              </button>
            </div>
            <img
              src={
                success === "success" ? "/success-icon.png" : "/error-icon.png"
              }
              alt="success icon"
            />
            <h2
              style={{
                fontWeight: 700,
                color: "#313B45",
                fontFamily: "Noto Sans, sans-serif",
                paddingBottom: "1.6rem",
              }}
            >
              {success === "success"
                ? "Sua ordem de carregamento foi gerada com sucesso!"
                : "Sua ordem de carregamento não foi gerada."}
            </h2>
            <p
              style={{
                fontWeight: 600,
                color: "#B0C1E1",
                fontFamily: "Open Sans, sans-serif",
                paddingBottom: "3.2rem",
              }}
            >
              {success === "success"
                ? "Agora você pode imprimir seus ticket gerados e visualizar informações em Meus Tickets."
                : success === "error"
                ? "Infelizmente, o servidor de automação está indisponível no momento. Tente outra vez."
                : success === "already registered"
                ? "Ordem de carregamento já registrada e carregada!"
                : "Você precisar selecionar o compartimento do produto"}
            </p>

            {success === "success" ? (
              <Button
                variant="contained"
                style={{ textTransform: "none" }}
                onClick={async () => {
                  try {
                    const response = await downloadOrder(
                      idErpResponse,
                      authContext ? authContext.customCenterID : ""
                    );
                    downloadPdf(response);
                  } catch (err) {
                    console.log("error", err);
                  } finally {
                  }
                }}
              >
                Imprimir
              </Button>
            ) : (
              <Button variant="contained" style={{ textTransform: "none" }}>
                Tentar novamente
              </Button>
            )}
          </Box>
        </Modal>
      </Form>
    </Formik>
  );
};

export default FormNewOrder;
