import { Button, ButtonProps, Link } from "@mui/material";
import React from "react";
import { Link as LinkRouter } from "react-router-dom";

export interface IButtonLinkWithName extends ButtonProps {
  route: string;
  title: string;
  routeExternal?: boolean;
  clickable?: boolean;
  active?: boolean;
}

const ButtonLinkWithName: React.FC<IButtonLinkWithName> = ({
  title,
  route,
  children,
  className,
  routeExternal,
  clickable = true,
  active = false,
  ...props
}) => {
  return (
    //@ts-ignore
    <Button
      component={clickable ? (routeExternal ? Link : LinkRouter) : "span"}
      to={routeExternal ? "" : route}
      href={routeExternal ? route : ""}
      fullWidth
      startIcon={React.Children.map(children, (child) => {
        // @ts-ignore
        return React.cloneElement(child, {
          "&:first-child": { fontSize: "2.2rem" },
        });
      })}
      style={{
        display: "flex",
        justifyContent: "left",
        opacity: active === false ? 0.3 : 1,
        textTransform: "none",
        textAlign: "left",
        padding: "0.8rem 9.6rem 0.8rem 2.4rem",
        marginTop: "2.4rem",
      }}
      {...props}
    >
      {title}
    </Button>
  );
};

export { ButtonLinkWithName };
