import React from "react";
import { Header, IHeaderProps } from "../Header";
import { LeftSideBar } from "../LeftSidebar";

export interface ILayoutFlexProps {
  headerProps?: IHeaderProps;
  enableFlexContent?: boolean;
}

const LayoutFlex: React.FC<ILayoutFlexProps> = ({
  children,
  headerProps,
  enableFlexContent = true,
}) => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",

        transition: "all 0.5s",
      }}
    >
      <div>
        <LeftSideBar />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "2rem 1rem",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Header {...headerProps} />
        <div>
          <div
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LayoutFlex };
