import { Box, Typography } from "@mui/material";
import { LayoutFlex } from "../../components/NewLayout";
import { FormProvider } from "./FormContext";
import FormNewOrder from "./FormNewOrder";

export const reasons = [
  "1 - Carregamento Oliveira Energia ",
  "2 - Carregamento CIF - Posto",
  "3 - Carregamento CIF - Usina",
  "4 - Carregamento FOB",
  "5 - Abastecimento Consumo Interno - Geradores",
  "6 - Abastecimento Consumo Interno - Motor de combate a incêndio",
  "7 - Abastecimento - Outros",
  "8 - Complemento de carregamento CIF",
  "9 - Complemento de carregamento FOB",
  "10 - Complemento de descarga",
  "11 - Erro Ticket - Sem retorno do painel",
  "12 - Erro Ticket - Lado da plataforma de carregamento",
  "13 - Outros",
];

export const NewOrderPage: React.FC = () => {
  return (
    <LayoutFlex>
      <Box sx={{ padding: "1.6rem 8rem", height: "100%" }}>
        <Typography
          sx={{
            fontSize: {
              "iphone-5-SE": "1.4rem",
              "iphone-6-7-8": "1.6rem",
              lg: "2.4rem",
            },
            fontFamily: "Noto Sans, sans-serif",
          }}
          variant="sectionTitle"
        >
          Nova ordem de carregamento
        </Typography>
        <FormProvider>
          <FormNewOrder />
        </FormProvider>
      </Box>
    </LayoutFlex>
  );
};
