import { LoadingButton } from '@mui/lab';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

export interface StyledButtonProps extends ButtonProps<'button'> {
  loading?: boolean;
}

const StyledButton = styled(
  ({ children, loading, sx, ...props }: StyledButtonProps) =>
    loading ? (
      // @ts-ignore
      <LoadingButton
        {...props}
        loading
        loadingPosition="start"
        sx={{
          ...sx,
          fontWeight: 600,
          textTransform: 'none',
          pt: '0.8rem',
          pb: '0.8rem',
        }}
        component={motion.button}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {children}
      </LoadingButton>
    ) : (
      // @ts-ignore
      <Button
        sx={{
          ...sx,
          fontWeight: 600,
          textTransform: 'none',
          pt: '0.8rem',
          pb: '0.8rem',
        }}
        component={motion.button}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        {...props}
      >
        {children}
      </Button>
    ),
)(({ theme }) => ({}));

export { StyledButton };