import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { Home } from "../../pages/Home";
import { NewOrderPage } from "../../pages/NewOrder";
import { OrdersPage } from "../../pages/Orders";

export const Routes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route path={routesConfig.HOME} component={Home} />
        <Route path={routesConfig.ORDERS} component={OrdersPage} />
        <Route path={routesConfig.NEW_ORDERS} component={NewOrderPage} />
        <Redirect from="*" to={routesConfig.HOME} />
      </Switch>
    </HashRouter>
  );
};
