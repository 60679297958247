/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import axios from "axios";
import jwtDecode from "jwt-decode";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Cookies from "universal-cookie";
import { appConfig } from "../config/appConfig";
import { Types } from "../ioc/types";
import { IHttpService } from "../modules/http/models/IHttpService";
import { useIoCContext } from "./IoCContext";
import {
  AuthCogCookieKeys,
  AuthenticationContextData,
  AuthenticationResponse,
  CognitoParsedToken,
} from "./models/IAuthContext";
import DashLoading from "../components/DashLoading";

// Constantes para valores literais usados no código
const BUFFER_TIME_MS = 59 * 60 * 1e3; // 59 * 60 * 1 = 3549 ms = 3,54 segundos
const TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS = 30e3; //  30e3 = 30 segundos

const AuthContext = createContext<AuthenticationContextData | null>(null);

const authServiceStandalone = axios.create({
  baseURL: appConfig.auth.url,
  timeout: appConfig.auth.timeout,
});

interface PropsAuthProvider {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<PropsAuthProvider> = ({
  children,
}: React.PropsWithChildren<PropsAuthProvider>) => {
  const [currentAuthState, setCurrentAuthState] =
    useState<AuthenticationContextData | null>(null);

  const iocContext = useIoCContext();
  const httpService = iocContext.serviceContainer.get<IHttpService>(
    Types.IHttpService
  );

  // Função para fazer a solicitação de atualização do token
  const refreshTokenRequest = async (
    currentTokenRefresh: string
  ): Promise<AuthenticationResponse> => {
    const { data: refreshTokenResponse } =
      await authServiceStandalone.post<AuthenticationResponse>(
        "/auth/refresh",
        {
          token: currentTokenRefresh,
        }
      );

    return refreshTokenResponse;
  };

  // Função para fazer de atualização do token
  const setTokenExpiration = useCallback(
    async (nextTokenRefreshTime: number): Promise<string | null> => {
      try {
        const data = await tokenRefresh(
          nextTokenRefreshTime - TOKEN_REFRESH_BEFORE_EXPIRATION_TIME_MS
        );
        if (data) {
          const builtToken = `${data.TokenType} ${data.AccessToken}`;
          httpService.setAuthorization(builtToken);
          return builtToken;
        }
        return null;
      } catch (error) {
        console.error(
          "[setTokenExpiration]: Failed to refresh access token in setTokenExpirationStrategy",
          error
        );
        return null;
      }
    },
    []
  );

  // Função principal de atualização do token
  const tokenRefresh = useCallback(
    async (refreshTokenExpirationTime?: number) => {
      try {
        const cookies = new Cookies();
        const cookiesTokenRefresh = cookies.get(AuthCogCookieKeys.refreshToken);

        // Verifica se o token atual existe no cookies da sessao
        if (!cookiesTokenRefresh) {
          throw new Error("Refresh token not found");
        }

        // Verifica se o token do cookie atual da sessao ainda não expirou
        if (
          refreshTokenExpirationTime &&
          +new Date() <= refreshTokenExpirationTime
        ) {
          return null;
        }

        const refreshTokenResponse = await refreshTokenRequest(
          cookiesTokenRefresh
        );
        const nextTokenRefreshTime =
          jwtDecode<CognitoParsedToken>(refreshTokenResponse.AccessToken).exp *
            1e3 -
          BUFFER_TIME_MS; // 1681917158000 ms
        httpService.setTokenExpirationStrategy(
          async () => await setTokenExpiration(nextTokenRefreshTime)
        );
        return refreshTokenResponse;
      } catch (error) {
        console.error("[tokenRefresh]: Failed to refresh access token", error);
        return null;
      }
    },
    []
  );

  const bootstrapAuthentication = useCallback(async () => {
    try {
      const data = await tokenRefresh();
      if (!data) {
        await logout();
        return;
      }

      httpService.setAuthorization(`${data.TokenType} ${data.AccessToken}`);

      const authData: AuthenticationContextData = {
        email: data.meta.email,
        name: data.meta.name,
        groups: data.meta.groups,
        refreshToken: data.RefreshToken,
        listCNPJ: data.meta.permissionSet.CNPJ,
        subject: data.meta.id,
        token: data.AccessToken,
        customCenterID: data.meta.permissionSet.CENTER_ID,
        customDocument: data.meta.permissionSet.DOCUMENT,
        username: data.meta.username,
        userID: data.meta.id,
        isAdmin: Boolean(
          data.meta.permissionSet.ROLES.some((role) => role.includes("admin"))
        ),
        bpID: `${data.meta.permissionSet.BPID}`,
        listAssessorIDAccepted: data.meta.permissionSet.PORTFOLIO_IDS,
        roles: data.meta.permissionSet.ROLES,
        sub: data.meta.id,
        SYSTEM_MODULES: data.meta.permissionSet.SYSTEM_MODULES,
        logout,
      };
      setCurrentAuthState(authData);
    } catch (error) {
      console.error(
        "[bootstrapAuthentication]: Failed to refresh access token",
        error
      );
      await logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    bootstrapAuthentication();
  }, []);

  const logout = useCallback(async () => {
    const hostname = window.location.hostname;
    if (
      process.env.REACT_APP_ENV === "development" ||
      hostname === "localhost"
    ) {
      window.location.href = appConfig.urlLogoutDev;
    } else if (hostname.includes("teste")) {
      window.location.href = appConfig.urlLogout;
    } else {
      window.location.href = appConfig.urlLogout;
    }
  }, []);
  return (
    <AuthContext.Provider value={currentAuthState}>
      {currentAuthState && currentAuthState.token ? children : <DashLoading />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth não pode ser utilizado fora de um AuthProvider");
  }
  return context;
};
