import { Box, IconButton, Link, Tooltip } from "@mui/material";
import React from "react";
import { Link as LinkRouter } from "react-router-dom";

export interface IButtonLinkWithoutName
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  route: string;
  routeExternal?: boolean;
  clickable?: boolean;
  active?: boolean;
}

const ButtonLinkWithoutName: React.FC<IButtonLinkWithoutName> = ({
  title,
  route,
  children,
  className,
  routeExternal,
  clickable = true,
  active = false,
  ...props
}) => {
  return (
    <Tooltip placement="right" arrow title={title}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          "&::after": {
            content: active === true ? '" "': '""',
            height: active === true ? 32 : 0,
            width: active === true ? 4 : 0,
            backgroundColor: active === true ? "#D91F05" : "",
            position: active === true ? "absolute" : "",
            right: active === true ? -4 : 0,
            top: active === true ? "50%" : "",
            transform: active === true ? "translate(0, -50%)" : "",
            borderRadius: active === true ? "5px" : "",
          },
        }}
        {...props}
      >
        <IconButton
          component={clickable ? (routeExternal ? Link : LinkRouter) : "span"}
          to={routeExternal ? "" : route}
          href={routeExternal ? route : ""}
          style={{ opacity: active === false ? 0.3 : 1 }}
        >
          {React.Children.map(children, (child) => {
            // @ts-ignore
            return React.cloneElement(child, {
              "&:first-child": {
                fontSize: "2.2rem",
              },
            });
          })}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export { ButtonLinkWithoutName };
