import { Box, BoxProps, SxProps, Theme } from "@mui/system";
import { generateFilter } from "colorize-filter";

export type IconType =
  | "home"
  | "menu"
  | "sair"
  | "increment"
  | "decrement"
  | "menu-icon"
  | "new-ticket-icon"
  | "my-tickets-icon";

export interface StyledIconsProps extends BoxProps<"img"> {
  iconType: IconType;
  /**
   * Hex color
   */
  iconColor?: string;
}

const StyledIcons: React.FC<StyledIconsProps> = ({
  iconType,
  iconColor,
  ...props
}) => {
  const sxProps: SxProps<Theme> = {
    width: "1.8rem",
    height: "1.8rem",
  };

  const selectIcon = () => {
    switch (iconType) {
      case "home": {
        return { src: "/images/icons/home.svg", alt: "home" };
      }
      case "menu": {
        return { src: "/images/icons/menu.svg", alt: "menu" };
      }
      case "sair": {
        return { src: "/images/icons/sair.svg", alt: "sair" };
      }
      case "increment": {
        return { src: "/images/icons/increment-icon.svg", alt: "increment" };
      }
      case "decrement": {
        return { src: "/images/icons/decrement-icon.svg", alt: "decrement" };
      }
      case "menu-icon": {
        return { src: "/images/icons/menu-icon.svg", alt: "menu-icon" };
      }
      case "new-ticket-icon": {
        return { src: "/images/icons/new-ticket-icon.svg", alt: "new-tickets" };
      }
      case "my-tickets-icon": {
        return {
          src: "/images/icons/my-tickets-icon.svg",
          alt: "my-tickets-icon",
        };
      }
    }
  };

  const icon = selectIcon();

  return icon ? (
    <Box
      component="img"
      {...props}
      {...icon}
      sx={{
        ...sxProps,
        ...props.sx,
        //@ts-ignore
        filter: iconColor ? generateFilter(iconColor) : undefined,
      }}
    />
  ) : (
    <></>
  );
};

export { StyledIcons };
