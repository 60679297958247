import { Box, Button, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchOrders } from "../../api";
import { LayoutFlex } from "../../components/NewLayout";
import { PropsOrderTable } from "../../components/TableOrders";
import { routesConfig } from "../../config/routes";
import { useAuth } from "../../contexts/AuthContext";

export const Home: React.FC = () => {
  // const height = useHeight();
  const authContext = useAuth();
  const history = useHistory();
  const [orders, setOrders] = useState<PropsOrderTable[]>([]);
  const [, setLoandigOrders] = useState(true);

  const getOrders = useCallback(async () => {
    try {
      setLoandigOrders(true);

      const response = await fetchOrders(
        moment().format("DD-MMM-yyyy"),
        authContext ? authContext.customCenterID : ""
      );

      setOrders(response);
    } catch (error) {
      setLoandigOrders(false);
    } finally {
      setLoandigOrders(true);
    }
  }, [authContext]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <LayoutFlex>
      <Box sx={{ padding: "1.6rem 8rem" }}>
        <Typography
          sx={{
            fontSize: {
              "iphone-5-SE": "1.4rem",
              "iphone-6-7-8": "1.6rem",
              lg: "2.4rem",
            },
            fontFamily: "Noto Sans, sans-serif",
          }}
          variant="sectionTitle"
        >
          Início
        </Typography>

        <Box
          sx={{ paddingTop: "3.2rem", display: "flex", flexDirection: "row" }}
        >
          <Button
            color="secondary"
            onClick={() => history.push(routesConfig.NEW_ORDERS)}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              borderBottom: "3px solid #1A74CD",
              padding: "1.6rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "4rem",
              height: "25rem",
              width: "25rem",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                backgroundColor: "#C5E1F7",
                width: "7rem",
                height: "7rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <img src="newticket.png" alt="ticket" />
            </div>

            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "2.4rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "2rem",
                  fontWeight: 600,
                  color: "#626166",
                }}
              >
                Novos tickets
              </Typography>
              <Typography style={{ color: "#B0C1E1", textTransform: "none" }}>
                Criar novo
              </Typography>
            </div>
          </Button>

          <Button
            color="secondary"
            onClick={() => history.push(routesConfig.ORDERS)}
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              borderBottom: "3px solid #30CAE9",
              padding: "1.6rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "4rem",
              height: "25rem",
              width: "25rem",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                backgroundColor: "#C2F5FF",
                width: "7rem",
                height: "7rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <img src="ticket.png" alt="ticket" />
            </div>

            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "2.4rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "2rem",
                  fontWeight: 600,
                  color: "#626166",
                }}
              >
                MEUS TICKETS
              </Typography>
              <Typography style={{ color: "#B0C1E1", textTransform: "none" }}>
                {orders.length} tickets
              </Typography>
            </div>
          </Button>
        </Box>
      </Box>
    </LayoutFlex>
  );
};
