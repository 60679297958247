import axios, { AxiosError } from "axios";
import AppError from "../utils/appError";
import { ICreateOrderDTO } from "../utils/interfaces";

export const createOrder = async ({ ...prop }: ICreateOrderDTO) => {
  try {
    const response = await axios.post<{ message: string; id_sale: string }>(
      "https://automacao.grupoatem.com.br/rest/order_in",
      { ...prop },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return response.data;
  } catch (err: any) {
    const error: AxiosError<{ message: string }> = err;
    if (error.response && error.response.data && error.response.data.message) {
      throw new AppError(
        error.response.data.message,
        "error",
      );
    } else {
      throw new AppError("Não foi possível realizar a operação", "error");
    }
  }
};

export const downloadOrder = async (orderId: string, center: string) => {
  const response = await axios.get(
    `https://automacao.grupoatem.com.br/rest/download_order/${center}?order_id=${orderId}`
  );

  return response.data;
};

export const fetchOrders = async (date: string, center: string) => {
  const response = await axios.get(
    `https://automacao.grupoatem.com.br/rest/orders_loading_status/${center}?start=${date}T00:00:00&end=${date}T23:59:59`
  );

  return response.data;
};
