import { Box, IconButton, Paper, SwipeableDrawer } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { useLayoutContext } from "../../hooks/LayoutContext";
import { ButtonLinkWithName } from "../ButtonLinkWithName";
import { ButtonLinkWithoutName } from "../ButtonLinkWithoutName";
import { StyledIcons } from "../StyledIcons";

const LeftSideBar: React.FC = () => {
  const history = useHistory();
  const layoutContext = useLayoutContext();
  return (
    <>
      <Paper
        component="aside"
        style={{
          gridArea: "leftSideBar",
          height: "100%",
          width: "8rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#D91F05",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "8rem",
            width: "100%",
          }}
        >
          <IconButton
            style={{ width: "5.6rem", height: "5.6rem" }}
            onClick={() => layoutContext.setOpenLeftSideBar(true)}
          >
            <StyledIcons iconType="menu-icon" color="#FFF" />
          </IconButton>
        </div>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            // 100% da view - 8rem do botão de abrir o side menu
            height: "calc(100% - 8rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <ButtonLinkWithoutName
            route={routesConfig.HOME}
            title="Home"
            active={history.location.pathname === routesConfig.HOME}
            style={{ marginTop: "1.6rem" }}
          >
            <StyledIcons iconType="home" />
          </ButtonLinkWithoutName>
          <ButtonLinkWithoutName
            route={routesConfig.NEW_ORDERS}
            title="Novos tickets"
            active={history.location.pathname === routesConfig.NEW_ORDERS}
            style={{ marginTop: "2.4rem" }}
          >
            <StyledIcons iconType="new-ticket-icon" />
          </ButtonLinkWithoutName>
          <ButtonLinkWithoutName
            route={routesConfig.ORDERS}
            title="Meus tickets"
            active={history.location.pathname === routesConfig.ORDERS}
            style={{ marginTop: "2.4rem" }}
          >
            <StyledIcons iconType="my-tickets-icon" />
          </ButtonLinkWithoutName>
        </Box>
      </Paper>
      {/* @ts-ignore */}
      <SwipeableDrawer
        anchor="left"
        open={layoutContext.openLeftSideBar}
        onClose={() => layoutContext.setOpenLeftSideBar(false)}
        onOpen={() => layoutContext.setOpenLeftSideBar(true)}
        style={{ width: "25%" }}
      >
        {/* <Link to={routesConfig.HOME}> */}
        <div
          style={{
            backgroundColor: "#D91F05",
            height: "8rem",
            width: "100%",
            marginBottom: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            padding: "2rem",
            borderBottomRightRadius: "5.6rem",
          }}
        >
          <IconButton
            style={{ width: "5.6rem", height: "5.6rem" }}
            onClick={() => layoutContext.setOpenLeftSideBar(false)}
          >
            <StyledIcons iconType="menu-icon" color="#FFF" />
          </IconButton>
        </div>
        {/* </Link> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            flexDirection: "column",
            // 100% da view - 8rem do botão de abrir o side menu
            height: "calc(100% - 8rem)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <ButtonLinkWithName
            active={routesConfig.HOME === history.location.pathname}
            route={routesConfig.HOME}
            title="Home"
          >
            <StyledIcons iconType="home" />
          </ButtonLinkWithName>

          <ButtonLinkWithName
            active={routesConfig.NEW_ORDERS === history.location.pathname}
            route={routesConfig.NEW_ORDERS}
            title="Novo Ticket"
          >
            <StyledIcons iconType="new-ticket-icon" />
          </ButtonLinkWithName>

          <ButtonLinkWithName
            active={routesConfig.ORDERS === history.location.pathname}
            route={routesConfig.ORDERS}
            title="Meus tickets"
          >
            <StyledIcons iconType="my-tickets-icon" />
          </ButtonLinkWithName>

          <div
            style={{
              marginTop: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "3.2rem",
            }}
          >
            <img src="atem-logo-sidebar.png" alt="logotipo" />
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export { LeftSideBar };
