import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import shadows from '@mui/material/styles/shadows';
import { createBreakpoints } from '@mui/system';

// shadow used in figma
shadows[1] = '5px 15px 30px 0px #7361611A';

const defaultTheme = createTheme();
const palette = createPalette({
  primary: { main: "#D91F05" },
  secondary: { main: "#736161" },
  background: { default: "#F4F7F9" },
  subTitles: { main: "#736161" },
  titles: { main: "#333E48" },
  tagsAndStatus: {
    0: "#0BB873",
    1: "#FDC742",
    2: "#F77B92",
    3: "#F1FFFA",
    4: "#FFF6DE",
    5: "#FFF5F7",
  },
});
const breakpoints = createBreakpoints({
  values: {
    ...defaultTheme.breakpoints.values,
    'iphone-5-SE': 320,
    'iphone-6-7-8': 375,
    'iphone-6-7-8-plus': 414,
  },
});

const theme = createTheme({ palette, breakpoints });

export const AtemTheme = () =>
  createTheme({
    ...theme,
    typography: {
      fontFamily: ['Open Sans', 'Poppins', 'Noto Sans', 'sans-serif'].join(','),
      htmlFontSize: 10,
      sectionTitle: {
        color: theme.palette.titles.main,
        fontFamily: 'Poppins',
        fontWeight: 700,
        display: 'block',
        [theme.breakpoints.up('iphone-5-SE')]: { fontSize: '2rem' },
        [theme.breakpoints.up('iphone-6-7-8')]: { fontSize: '2.4rem' },
      },
      sectionSubtitle: {
        display: 'block',
        color: theme.palette.subTitles.main,
        [theme.breakpoints.up('iphone-5-SE')]: { fontSize: '1rem' },
        [theme.breakpoints.up('iphone-6-7-8')]: { fontSize: '1.2rem' },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: { fontSize: 10 },
          '#root': { overflowX: 'hidden' },
        },
      },
      MuiCard: { defaultProps: { sx: { boxShadow: 1 } } },
      MuiButton: {
        styleOverrides: {
          root: { padding: '1.2rem 2.2rem', fontSize: '1.6rem' },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '2.8rem',
            height: '1.6rem',
            padding: 0,
            display: 'flex',
            '& .MuiSwitch-switchBase': {
              padding: '0.2rem',
              '&.Mui-checked': {
                transform: 'translateX(1.2rem)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  opacity: 1,
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.primary.dark
                      : theme.palette.primary.main,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 0.2rem 0.4rem 0 rgb(0 35 11 / 20%)',
              width: '1.2rem',
              height: '1.2rem',
              borderRadius: '0.6rem',
            },
            '& .MuiSwitch-track': {
              borderRadius: '0.8rem',
              opacity: 1,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,255,255,.35)'
                  : 'rgba(0,0,0,.25)',
              boxSizing: 'border-box',
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            overflow: 'visible',
            borderRadius: '0.8rem',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: theme.spacing(1.5),
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 0,
              marginRight: theme.spacing(2.5),
              width: 10,
              height: 10,
              backgroundColor: theme.palette.background.paper,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      },
    },
    shadows,
  });

declare module '@mui/material/styles' {
  interface Palette {
    subTitles: Palette['primary'];
    titles: Palette['primary'];
    /**
     * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.043137254901960784&fundo_g=0.7215686274509804&fundo_b=0.45098039215686275&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10) ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9921568627450981&fundo_g=0.7803921568627451&fundo_b=0.25882352941176473&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10) ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9686274509803922&fundo_g=0.4823529411764706&fundo_b=0.5725490196078431&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10) ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9450980392156862&fundo_g=1&fundo_b=0.9803921568627451&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10) ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9647058823529412&fundo_b=0.8705882352941177&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10) ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9607843137254902&fundo_b=0.9686274509803922&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
     */
    tagsAndStatus: {
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.043137254901960784&fundo_g=0.7215686274509804&fundo_b=0.45098039215686275&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      0: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9921568627450981&fundo_g=0.7803921568627451&fundo_b=0.25882352941176473&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      1: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9686274509803922&fundo_g=0.4823529411764706&fundo_b=0.5725490196078431&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      2: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9450980392156862&fundo_g=1&fundo_b=0.9803921568627451&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      3: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9647058823529412&fundo_b=0.8705882352941177&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      4: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9607843137254902&fundo_b=0.9686274509803922&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      5: string;
    };
  }

  interface TypographyVariants {
    sectionTitle: React.CSSProperties;
    sectionSubtitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    sectionTitle?: React.CSSProperties;
    sectionSubtitle?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    subTitles?: PaletteOptions['primary'];
    titles?: PaletteOptions['primary'];
    tagsAndStatus?: {
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.043137254901960784&fundo_g=0.7215686274509804&fundo_b=0.45098039215686275&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      0: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9921568627450981&fundo_g=0.7803921568627451&fundo_b=0.25882352941176473&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      1: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9686274509803922&fundo_g=0.4823529411764706&fundo_b=0.5725490196078431&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      2: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=0.9450980392156862&fundo_g=1&fundo_b=0.9803921568627451&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      3: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9647058823529412&fundo_b=0.8705882352941177&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      4: string;
      /**
       * ![](https://www.4devs.com.br/4devs_gerador_imagem.php?acao=gerar_imagem&txt_largura=24&txt_altura=24&extensao=jpg&fundo_r=1&fundo_g=0.9607843137254902&fundo_b=0.9686274509803922&texto_r=0&texto_g=0&texto_b=0&texto=&tamanho_fonte=10)
       */
      5: string;
    };
  }

  interface BreakpointOverrides {
    'iphone-6-7-8': true;
    'iphone-6-7-8-plus': true;
    'iphone-5-SE': true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sectionTitle: true;
    sectionSubtitle: true;
  }
}
