export interface IClient {
  products: IProduct[];
}

export interface IProduct {
  idx?: string;
  id: string;
  product: string;
  quantity: number;
}

export interface SupplyResponse extends IProduct {
  ticket_id: number;
}
export interface IFormCreateOrderService {
  filial: string;
  operacao: string;
  id_erp: string;
  placa: string;
  products: IProduct[];
  productSelected: string;
  user_name: string;
  user_document: string;
  reason: string;
  comment?: string;
  source: "MANUAL";
}

export interface ICreateOrderDTO {
  filial: string;
  comment?: string;
  operacao?: string;
  id_sale: string;
  plate: string;
  compartments: {
    id: string;
    product: string;
    quantity: string;
  }[];
  user_name: string;
  user_document: string;
  reason: string;
  source: "MANUAL";
}

// export const products = [
//   { codigo: "GASC", produto: "Gasolina C" },
//   { codigo: "GASA", produto: "Gasolina A" },
//   { codigo: "AS500", produto: "Diesel A S500" },
//   { codigo: "BS500", produto: "Diesel B S500" },
//   { codigo: "AS10", produto: "Diesel A S10" },
//   { codigo: "BS10", produto: "Diesel B S10" },
//   { codigo: "AEHC", produto: "Hidratado" },
//   { codigo: "AEAC", produto: "Anidro" },
//   { codigo: "B100", produto: "Biodiesel" },
//   { codigo: "ODMR", produto: "Marítimo" },
// ];

export const products = [
  { product: "GASC" },
  { product: "GAA" },
  { product: "ODM" },
  { product: "ODMB" },
  { product: "OS10" },
  { product: "OB10" },
  { product: "AEHC" },
  { product: "AEAC" },
  { product: "B100" },
  { product: "ODMR" },
];
