import { Box } from "@mui/material";
import React from "react";

export interface TagStatusProps {
  status: number;
}

// 1 => aguardando carregamento
// 2 => carregamento finalizado
// 3 => carregamento incompleto
// 5 => aguardando descarregamento
// 6 => descarregamento finalizado
// 7 => descarregamento incompleto
// 9 => ticket cancelado
// 23 => em carregamento

const TagStatus: React.FC<TagStatusProps> = (props) => {
  return (
    <Box
      sx={{
        display: "inline",
        padding: "0.6rem 1rem",
        width: "100%",
        borderRadius: "0.7rem",
        color:
          props.status === 1
            ? "#D91F05"
            : props.status === 2
            ? "#0BB873"
            : props.status === 23
            ? "#FAD217"
            : "#626166",
        backgroundColor:
          props.status === 1
            ? "#FFD8D2"
            : props.status === 2
            ? "#D3FEED"
            : props.status === 23
            ? "#FFF2D1"
            : "#EEF0F5",
      }}
    >
      {props.status === 1
        ? "Aguardando carregamento"
        : props.status === 2
        ? "Carregamento finalizado"
        : props.status === 23
        ? "Carregamento em andamento"
        : "Outros"}
    </Box>
  );
};

export default TagStatus;
