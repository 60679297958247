import {
  Box,
  Button,
  Grid,
  OutlinedTextFieldProps,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { useAuth } from "../../contexts/AuthContext";
import { StyledIcons } from "../StyledIcons";

export interface IHeaderProps {
  showSearchBar?: boolean;
  searchBarProps?: Omit<OutlinedTextFieldProps, "variant">;
}

const Header: React.FC<IHeaderProps> = ({
  showSearchBar = true,
  searchBarProps,
}) => {
  //   const layoutContext = useLayoutContext();
  const headerRef = useRef<HTMLElement | null>(null);
  const authContext = useAuth();
  const history = useHistory();

  return (
    <header ref={headerRef}>
      <Grid
        container
        alignItems="center"
        style={{ height: "100%", paddingRight: "1.6rem" }}
      >
        <Grid item container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Typography
              style={{ fontWeight: 600, padding: "2.4rem" }}
              color="primary"
            >
              {`FILIAL - ${authContext?.customCenterID}`}
            </Typography>

            <Button
              size="large"
              color="primary"
              style={{
                fontSize: "1.6rem",
                width: "11rem",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: 600,
              }}
              onClick={async () => {
                await authContext?.logout();
                history.replace(routesConfig.LOGIN);
              }}
            >
              {/* {authContext.loadingLogout ? (
                <CircularProgress
                  sx={{
                    height: "2.4rem !important",
                    width: "2.4rem !important",
                  }}
                />
              ) : (
                <StyledIcons iconType="sair" />
              )}*/}
              <StyledIcons iconType="sair" />
              SAIR
            </Button>
          </Box>
        </Grid>
      </Grid>
    </header>
  );
};

export { Header };
