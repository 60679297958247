import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StyledIcons } from "../../../components/StyledIcons";
import { compartment } from "../../../config/appConfig";
import { onlyNumber } from "../../../utils";
import { IFormCreateOrderService, IProduct } from "../../../utils/interfaces";
// import IncrementIcon from "../../../../public/images/icons/increment-icon.png";

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    margin: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    color: "#626166",
    padding: "2.4rem",
    border: "none",
    borderRadius: "15px",
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
  },
}));

interface IProductSelecteToExclude {
  [propName: string]: boolean;
}
interface TableProductsProps {}

interface TableProductRowProps {
  product: IProduct;
  setProductSelectedToExclude: Dispatch<
    SetStateAction<IProductSelecteToExclude>
  >;
  productSelectedToExclude: IProductSelecteToExclude;
  key: any;
  id: number;
}

const TableProductRow: React.FC<TableProductRowProps> = ({
  product,
  productSelectedToExclude,
  setProductSelectedToExclude,
  id,
}) => {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<IFormCreateOrderService>();
  const theme = useTheme();
  const [counter] = useState(1000);

  const incrementCounter = () => setQuantity(product.quantity + 1000);
  let decrementCounter = () => setQuantity(product.quantity - 1000);

  if (counter <= 0) {
    decrementCounter = () => setQuantity(1000);
  }

  const setCompartment = (value: string) => {
    const products = values.products.map((productID) => {
      if (product.product === productID.product) {
        product.id = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };

  const setQuantity = (value: number) => {
    const products = values.products.map((productID) => {
      if (product.product === productID.product) {
        product.quantity = value;
      }
      return productID;
    });

    setFieldValue("products", products);
  };

  // const getError = (key: string) => {
  //   const idx = values.products.findIndex(
  //     (productID) => productID.produto === product.produto
  //   );
  //   const keyPath = `products[${idx}].${key}`;
  //   const error = getIn(errors, keyPath);
  //   const touch = getIn(touched, keyPath);
  //   return touch && error ? error : null;
  // };

  return (
    <>
      <TableRow>
        <TableCell
          // padding="checkbox"
          sx={{
            borderRadius: "1.6rem 0rem 0rem 1.6rem",
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          <Checkbox
            onClick={() =>
              setProductSelectedToExclude({
                ...productSelectedToExclude,
                [product.id]: !productSelectedToExclude[product.id],
              })
            }
            checked={productSelectedToExclude[product.id]}
          />
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
          }}
        >
          {product.product}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
            fontSize: "1.6rem",
            borderRadius: "0rem 1.6rem 1.6rem 0rem",
          }}
        >
          {product.product}
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
            backgroundColor: "#F4F7F9",
            padding: 0,
            margin: 0,
          }}
          align="center"
        >
          <FormControl>
            <Select
              // error={!!getError("compartment")}
              name="compartment"
              id="compartment"
              displayEmpty={true}
              onBlur={() => setFieldTouched("compartment", true)}
              onChange={(e: { target: { value: string } }) =>
                setCompartment(e.target.value)
              }
              sx={{
                width: 110,
              }}
              value={product.id ? product.id : ""}
              input={<BootstrapSelect />}
            >
              <MenuItem disabled value="">
                <em>Selecione o compartimento</em>
              </MenuItem>
              {compartment.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText error={!!getError("compartment")}>
              {getError("compartment")}
            </FormHelperText> */}
            {/* {touched.&& errors.id_erp && (
              <div style={{ color: "#D91F05", fontSize: "0.8rem" }}>
                {errors.id_erp}
              </div>
            )} */}
          </FormControl>
        </TableCell>
        <TableCell
          sx={{
            border: "none",
            color: "#626166",
            fontWeight: 400,
            backgroundColor: "#F4F7F9",
            padding: 0,
            margin: 0,
          }}
        >
          <FormControl fullWidth>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                backgroundColor: "white",
                border: "none",
                fontSize: "1.6rem",
                color: "#626166",
                borderRadius: "15px",
                padding: "1.6rem 2.4rem",
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={decrementCounter}
              >
                <StyledIcons
                  iconType="decrement"
                  iconColor={theme.palette.primary.main}
                />
              </IconButton>

              <input
                onChange={(e: { target: { value: string } }) =>
                  setQuantity(Number(onlyNumber(e.target.value)))
                }
                value={product.quantity}
                style={{
                  outline: "none",
                  border: "none",
                  fontSize: "1.6rem",
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: 400,
                  color: "#626166",
                  textAlign: "center",
                  width: 120,
                }}
                placeholder=""
              />
              <IconButton
                color="primary"
                component="label"
                onClick={incrementCounter}
              >
                <StyledIcons
                  iconType="increment"
                  iconColor={theme.palette.primary.main}
                />
              </IconButton>
            </div>
          </FormControl>
        </TableCell>
      </TableRow>
      <Box sx={{ padding: "0.4rem", backgroundColor: "#F4F7F9" }}> </Box>
    </>
  );
};

const TableProducts: React.FC<TableProductsProps> = () => {
  const [productSelectedToExclude, setProductSelectedToExclude] =
    useState<IProductSelecteToExclude>({});
  const { values, setFieldValue } = useFormikContext<IFormCreateOrderService>();

  const removeProducts = () => {
    const exclude = Object.keys(productSelectedToExclude);
    let products = values.products.filter((ele) => {
      const findOk = exclude.find((key) => ele.id === key);

      return findOk ? false : true;
    });

    setProductSelectedToExclude({});
    setFieldValue("products", products);
  };

  useEffect(() => {
    setProductSelectedToExclude({});
    setFieldValue("products", []);
  }, [setFieldValue]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "0.8rem",
        backgroundColor: "transparent",
        boxShadow: "none",
      }}
    >
      <Collapse
        in={
          !!Object.keys(productSelectedToExclude).find((ele) =>
            productSelectedToExclude[ele] === true ? true : false
          )
        }
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <Typography>
              {Object.keys(productSelectedToExclude).reduce(
                (previusValue, nextValue) => {
                  if (productSelectedToExclude[nextValue]) {
                    previusValue += 1;
                    return previusValue;
                  } else {
                    return previusValue;
                  }
                },
                0
              )}{" "}
              Selecionados
            </Typography>
          </div>
          <div>
            <Tooltip title="Remover Selecionados" arrow placement="top">
              <Button aria-label="delete" onClick={() => removeProducts()}>
                Remover
              </Button>
            </Tooltip>
          </div>
        </Toolbar>
      </Collapse>
      <Table>
        <TableHead
          sx={{
            backgroundColor: "#EEF0F5",
            color: "#626166",
            marginBottom: "1.2rem",
            borderRadius: "0.8rem",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                color: "#626166",
                border: "none",
                borderRadius: "0.8rem 0rem 0rem 0.8rem",
              }}
            >
              {/* <Checkbox
                onChange={() => selectAll()}
                checked={isCheckedAll()}
                indeterminate={isSomeSelected()}
              /> */}
            </TableCell>
            <TableCell sx={{ color: "#626166", border: "none" }}>
              Código
            </TableCell>
            <TableCell sx={{ color: "#626166", border: "none" }}>
              Descrição
            </TableCell>
            <TableCell
              sx={{ color: "#626166", border: "none", width: 120 }}
              align="center"
            >
              Compartimento
            </TableCell>
            <TableCell sx={{ color: "#626166", border: "none", width: 240 }}>
              Quantidade(LT)
            </TableCell>
          </TableRow>
        </TableHead>
        <div style={{ backgroundColor: "#F4F7F9", padding: "0.8rem" }}> </div>
        <TableBody sx={{ backgroundColor: "white" }}>
          {values.products.map((product, idx) => {
            return (
              <TableProductRow
                key={product.idx}
                id={idx}
                product={product}
                productSelectedToExclude={productSelectedToExclude}
                setProductSelectedToExclude={setProductSelectedToExclude}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TableProducts };
