import React, { createContext, useCallback, useContext, useState } from "react";

interface FormContext {
  loading: boolean;
  loadingCreateOrder: boolean;
  dataOrder: IClient | null;
  setLoading(loading: boolean): void;
  setLoadingCreateOrder(loading: boolean): void;
  setDataOrder(data: IClient): void;
}

export interface IProduct {
  compartimento: string;
  produto: string;
  quantidade: string;
}

export interface IClient {
  products: IProduct[];
}

const FormCreateOrderContext = createContext<FormContext>({} as FormContext);

const FormProvider: React.FC = ({ children }) => {
  const [loading, setLoadingState] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrderState] = useState(false);
  const [dataOrder, setDataOrder] = useState<IClient | null>(null);

  const setLoading = useCallback((loading: boolean) => {
    setLoadingState(loading);
  }, []);

  const setLoadingCreateOrder = useCallback((loading: boolean) => {
    setLoadingCreateOrderState(loading);
  }, []);

  return (
    <FormCreateOrderContext.Provider
      value={{
        loading,
        dataOrder,
        setLoading,
        setLoadingCreateOrder,
        loadingCreateOrder,
        setDataOrder,
      }}
    >
      {children}
    </FormCreateOrderContext.Provider>
  );
};

const useForm = (): FormContext => {
  const context = useContext(FormCreateOrderContext);
  if (!context) {
    throw new Error("useForm deve ser utilizado dentro de um FormProvider");
  }
  return context;
};

export { useForm, FormProvider };
