import { datadogRum } from "@datadog/browser-rum";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { Routes } from "./components/Routes";
import { AtemTheme } from "./components/Theme";

import { LayoutProvider } from "./hooks/LayoutContext";
import { AuthProvider } from "./contexts/AuthContext";
import { IoCProvider } from "./contexts/IoCContext";

const App: React.FC = () => {
  datadogRum.init({
    applicationId: "e8c3c98b-d180-4312-a6e0-60537aab8d62",
    clientToken: "pubfd0279564a92dade29553eb694c570e8",
    site: "datadoghq.com",
    service: "carregamento-automacao-geracao-ticket",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();

  return (
    <IoCProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={AtemTheme()}>
          <AuthProvider>
            <LayoutProvider>
              <CssBaseline />
              <Routes />
            </LayoutProvider>
          </AuthProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </IoCProvider>
  );
};

export default App;
