import { Container } from "inversify";
import AxiosHttpService from "../modules/http/implementations/AxiosHttpService";
import { IHttpService } from "../modules/http/models/IHttpService";
import { IAuthService } from "../modules/user/models/IAuthService";
import { AuthService } from "../modules/user/services/AuthService";
import { Types } from "./types";

const appIocContainer = new Container({ defaultScope: "Singleton" });

function registerNewService<T>(
  type: string | symbol,
  constructor: new (...args: any[]) => T
) {
  appIocContainer.bind<T>(type).to(constructor);
}
registerNewService<IHttpService>(Types.IHttpService, AxiosHttpService);
registerNewService<IAuthService>(Types.User.IAuthService, AuthService);

export { appIocContainer };
