import { interfaces } from "inversify";
import React, { createContext, useContext } from "react";
import { appIocContainer } from "../ioc";

export interface IIoCContext {
  serviceContainer: interfaces.Container;
}

const IoCContext = createContext<IIoCContext>({} as IIoCContext);

const IoCProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <IoCContext.Provider value={{ serviceContainer: appIocContainer }}>
      {children}
    </IoCContext.Provider>
  );
};

const useIoCContext = (): IIoCContext => {
  const context = useContext(IoCContext);
  if (!Object.keys(context).length) {
    throw new Error("useIoCContext must be used within an IoCProvider");
  }
  return context;
};

export { IoCProvider, useIoCContext };
