import { Box, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import lodash from "lodash";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { fetchOrders } from "../../api";
import { LayoutFlex } from "../../components/NewLayout";
import TableOrders, {
  PropsOrderTable,
  PropsResponseGetOrders,
} from "../../components/TableOrders";
import { useAuth } from "../../contexts/AuthContext";

export const OrdersPage: React.FC = () => {
  const authContext = useAuth();
  const [orders, setOrders] = useState<PropsOrderTable[]>([]);
  const [loadingOrders, setLoandigOrders] = useState(false);
  const [value, setValue] = useState<Date | null | string>(
    moment().format("DD-MMM-yyyy")
  );

  const handleChange = (newValue: Date | null | string) => {
    setValue(moment(newValue).format("DD-MMM-yyyy"));
  };

  //10 - aguardando carregamento
  //20 - carregamento em andamento
  //30 - carregamento concluido
  //40 - outros
  function convertToTable(orders: []) {
    const response = orders.reduce(
      (
        orderHash: { [x: string]: PropsOrderTable },
        currentOrder: PropsResponseGetOrders
      ) => {
        if (typeof orderHash[currentOrder.order_id] !== "undefined") {
          orderHash[currentOrder.order_id].tickets.push(currentOrder);
          //@ts-ignore
          orderHash[currentOrder.order_id].count_status_ticket[
            currentOrder.status.toString()
          ]++;
          return orderHash;
        }
        orderHash[currentOrder.order_id] = {
          order_id: currentOrder.order_id,
          plate: currentOrder.truck_plate,
          source: currentOrder.source,
          reason: currentOrder.reason,
          comment: currentOrder.comment,
          count_status_ticket: {
            "1": 0,
            "2": 0,
            "3": 0,
            "5": 0,
            "6": 0,
            "7": 0,
            "9": 0,
            "23": 0,
          },
          user_name: currentOrder.user_name,
          tickets: [currentOrder],
          created: new Date(currentOrder.created),
        };
        //@ts-ignore
        if (typeof orderHash[currentOrder.order_id] !== "undefined") {
          //@ts-ignore
          orderHash[currentOrder.order_id].count_status_ticket[
            currentOrder.status.toString()
          ] = 1;
        }
        return orderHash;
      },

      {}
    );
    return Object.values(response).map((item) => {
      if (item.tickets.length === item.count_status_ticket["1"]) {
        item.status = 10;
      } else if (item.tickets.length === item.count_status_ticket["2"]) {
        item.status = 30;
      } else if (
        (item.count_status_ticket["23"] > 0 &&
          item.tickets.length ===
            item.count_status_ticket["1"] +
              item.count_status_ticket["2"] +
              item.count_status_ticket["23"]) ||
        item.count_status_ticket["1"] + item.count_status_ticket["2"] ===
          item.tickets.length
      ) {
        item.status = 20;
      } else {
        item.status = 40;
      }
      return item;
    });
  }

  const getOrders = useCallback(async () => {
    try {
      setLoandigOrders(true);
      if (value) {
        const response = await fetchOrders(
          value.toString(),
          authContext ? authContext?.customCenterID : ""
        );
        let responseWithConverting = convertToTable(response);

        setOrders(
          lodash.orderBy(
            responseWithConverting,
            ["status", "created"],
            ["asc", "desc"]
          )
        );
      }
    } catch (error) {
      setLoandigOrders(false);
    } finally {
      setLoandigOrders(false);
    }
  }, [value, authContext]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <LayoutFlex>
      <Box sx={{ padding: "1.6rem 8rem", height: "100%" }}>
        <Typography
          sx={{
            fontSize: {
              "iphone-5-SE": "1.4rem",
              "iphone-6-7-8": "1.6rem",
              lg: "2.4rem",
            },
            fontFamily: "Noto Sans, sans-serif",
          }}
          variant="sectionTitle"
        >
          Minhas ordens de carregamento
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <DesktopDatePicker
            inputFormat="dd-MMM-yyyy"
            mask="__/__/____"
            maxDate={new Date()}
            value={value}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: false,
                }}
                {...params}
                sx={{
                  backgroundColor: "#FFF",
                  padding: "0.8rem 2.4rem",

                  color: "#626166",
                  borderRadius: "5px",
                  "& .MuiInput-root": {
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: 0,
                    },
                    color: "#626166",
                    fontSize: "1.6rem",
                  },
                }}
              />
            )}
          />
        </Box>

        <Box
          sx={{ paddingTop: "3.2rem", display: "flex", flexDirection: "row" }}
        >
          {orders && (
            <TableOrders
              items={orders}
              rowsPerPage={10}
              loading={loadingOrders}
            />
          )}
        </Box>
      </Box>
    </LayoutFlex>
  );
};
